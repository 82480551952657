import React, { useState } from "react"
import ReactMarkdown from "react-markdown"
import Gallery from '@browniebroke/gatsby-image-gallery'
import FsLightbox from 'fslightbox-react';

const Profile = ({ actor }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1
  });
  const openLightboxOnSlide = (number) => {
    setLightboxController({
    toggler: !lightboxController.toggler,
    slide: number
    });
  }
  const labelData = (actor.locale !== 'mn') ? 
    {
        height: "Height",
        weight: "Weight",
        age: "Age",
        body: "Body",
        hair: "Hair",
        eyes: "Eyes",
        films: "Films",
        year: "Year",
        movieTitle: "Movie name",
        movieRole: "Role",
        roleType: "Type",
        movieLinks: "Links",
        main: "Main role",
        supporting: "Supporting",
        awards: "Awards",
        awardTitle: "Award",
        theatricals: "Theatricals",
        theaterTitle: "Title",
        theaterRole: "Role",
        theaterLinks: "Links",
        songs: "Songs",
        songTitle: "Title",
        songBand: "Band",
        songLinks: "Links",
        trainings: "Trainings",
        trainingSchool: "School",
        trainingDegree: "Achievment",
        credits: "Credits",
        creditTitle: "Title",
        creditRole: "Role",
        skills: "Skills",
        occupation: "Occupation",
        languages: "Languages",
        location: "Location",
    } : {
        height: "Өндөр",
        weight: "Жин",
        age: "Нас",
        body: "Бие",
        hair: "Үс",
        eyes: "Нүд",
        films: "Кино",
        year: "Он",
        movieTitle: "Киноны нэр",
        movieRole: "Дүр",
        roleType: "Төрөл",
        movieLinks: "Холбоос",
        main: "Гол дүр",
        supporting: "Туслах дүр",
        awards: "Шагнал",
        awardTitle: "Шагнал",
        theatricals: "Театрын уран бүтээл",
        theaterTitle: "Нэр",
        theaterRole: "Дүр",
        theaterLinks: "Холбоос",
        songs: "Дуу",
        songTitle: "Нэр",
        songBand: "Хамтлаг",
        songLinks: "Холбоос",
        trainings: "Боловсрол",
        trainingSchool: "Сургууль",
        trainingDegree: "Цол",
        credits: "Бусад уран бүтээл",
        creditTitle: "Title",
        creditRole: "Role",
        skills: "Ур чадварууд",
        occupation: "Мэргэжил",
        languages: "Хэл",
        location: "Байршил",
    };

  const getAge = (dateString) => {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) 
      {
        age--;
      }
      return age;
    }

  const images = actor.images.map(({ localFile }) => localFile.childImageSharp)
  const videos = actor.Video.map(({code}) => `https://www.youtube.com/watch?v=${code}`)
  const thumbVideos = actor.Video.map(({code}) => `https://img.youtube.com/vi/${code}/maxresdefault.jpg`)
  return (
    <>
      <div className="profile-entry container">
        <div className="profile-photos">
          <div className="row">
            <div className="col-12 thumbnail-photo">
                {/* Photo gallery					 */}
                <Gallery images={images} mdColWidth="16" />
                
            </div>
          </div>
          <div className="row">
            {thumbVideos.map((thumbImg, index) => (
              <div key={index} className="col-2" onClick={() => openLightboxOnSlide(index+1)}>
                <img src={thumbImg} />
              </div>
            ))}
            <FsLightbox
              toggler={lightboxController.toggler}
              sources={videos}
              slide={lightboxController.slide}
              />
          </div>
        </div>
        <div className="row">
          <section className="col-md-9">
            <div className="profile-info">
              <header className="profile-header">
                {actor.locale == 'mn' && (
                  <h2 className="profile-title">{actor.lastname} {actor.name}</h2>
                )}
                {actor.locale == 'en' && (
                  <h2 className="profile-title">{actor.name} {actor.lastname}</h2>
                )}
              </header>
              <div className="model-attr">
                <p className="attr-item">
                  <span className="attr-name">{labelData.height}:</span>
                  <span className="attr-value">{actor.height}</span>
                </p>
                <p className="attr-item">
                  <span className="attr-name">{labelData.weight}:</span>
                  <span className="attr-value">{actor.weight}</span>
                </p>
                <p className="attr-item">
                  <span className="attr-name">{labelData.age}:</span>
                  <span className="attr-value">{getAge(actor.birth_at)}</span>
                </p>
                <p className="attr-item">
                  <span className="attr-name">{labelData.body}:</span>
                  <span className="attr-value">{actor.body ? actor.body.name: ''}</span>
                </p>
                <p className="attr-item">
                  <span className="attr-name">{labelData.hair}:</span>
                  <span className="attr-value">{actor.hair ? actor.hair.name: ''}</span>
                </p>
                <p className="attr-item">
                  <span className="attr-name">{labelData.eyes}:</span>
                  <span className="attr-value">{actor.eye ? actor.eye.name: ''}</span>
                </p>
              </div>
              <div className="model-description">
                <ReactMarkdown>{actor.bio}</ReactMarkdown>
              </div>
            </div>
          </section>
          <aside className="col-md-3">
            <p className="soc-icons">
              {actor.Social && actor.Social.facebook && (
                <a href={actor.Social.facebook} target="_blank"><i className="fa fa-facebook-f"></i></a>
              )}
              {actor.Social && actor.Social.instagram && (
                <a href={actor.Social.instagram} target="_blank"><i className="fa fa-instagram"></i></a>
              )}
              {actor.Social && actor.Social.web && (
                <a href={actor.Social.web} target="_blank"><i className="fa fa-globe"></i></a>
              )}
              {actor.Profile && actor.Profile.youtube && (
                <a href={actor.Profile.youtube} target="_blank"><i className="fa fa-youtube"></i></a>
              )}
              {actor.Profile && actor.Profile.kinosan && (
                <a href={actor.Profile.kinosan} target="_blank"><i className="fa fa-film"></i></a>
              )}
              {actor.Profile && actor.Profile.imdb && (
                <a href={actor.Profile.imdb} target="_blank"><i className="fa fa-imdb"></i></a>
              )}
            </p>
          </aside>
        </div>
        <div className="row">
          <section className="col-md-9">
            <table className="table">
              <tbody>
                <tr>
                  <td>{labelData.skills}</td>
                  <td className="skills">{actor.skills && actor.skills.map(skillData => (
                    <span className="skill-item" key={skillData.id}>
                      <a>{skillData.title}</a>
                    </span>
                  ))}
                  </td>
                </tr>
                <tr>
                  <td>{labelData.languages}</td>
                  <td className="skills">{actor.languages && actor.languages.map(langData => (
                    <span className="skill-item" key={langData.id}>
                      <a>{langData.name}</a>
                    </span>
                  ))}
                  </td>
                </tr>
                <tr>
                  <td>{labelData.occupation}</td>
                  <td className="skills">{actor.occupation && (
                    <span className="skill-item">
                      <a>{actor.occupation.title}</a>
                    </span>
                  )}
                  </td>
                </tr>
                <tr>
                  <td>{labelData.location}</td>
                  <td className="skills">{actor.location && (
                    <span className="skill-item">
                      <a>{actor.location.name}</a>
                    </span>
                  )}
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
      </div>

      
      {actor.Films.length > 0 && (
        <div className="profile-reviews container">
          <div className="row">
            <section className="col-lg-9 order-lg-first">
              <h3 className="section-title lines">{labelData.films}</h3>
              <div className="rewiew-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{labelData.year}</th>
                      <th scope="col">{labelData.movieTitle}</th>
                      <th scope="col">{labelData.movieRole}</th>
                      <th scope="col">{labelData.roleType}</th>
                      <th scope="col">{labelData.movieLinks}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actor.Films.sort((a, b) => a.year - b.year).map(filmData => (
                      <tr key={filmData.id}>
                        <td>{filmData.year}</td>
                        <td>{filmData.title}</td>
                        <td>{filmData.role}</td>
                        <td>{labelData[filmData.type]}</td>
                        <td>
                          <span className="soc-icons">
                            {filmData.movieLink && filmData.movieLink.youtube && (
                              <a href={filmData.movieLink.youtube} target="_blank"><i className="fa fa-youtube"></i></a>
                            )}
                            {filmData.movieLink && filmData.movieLink.kinosan && (
                              <a href={filmData.movieLink.kinosan} target="_blank"><i className="fa fa-film"></i></a>
                            )}
                            {filmData.movieLink && filmData.movieLink.imdb && (
                              <a href={filmData.movieLink.imdb} target="_blank"><i className="fa fa-imdb"></i></a>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      )}
      {actor.Awards.length > 0 && (
        <div className="profile-reviews container">
          <div className="row">
            <section className="col-lg-9 order-lg-first">
              <h3 className="section-title lines">{labelData.awards}</h3>
              <div className="rewiew-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{labelData.year}</th>
                      <th scope="col">{labelData.awardTitle}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actor.Awards.sort((a, b) => a.year - b.year).map(awardData => (
                      <tr key={awardData.id}>
                        <td>{awardData.year}</td>
                        <td>{awardData.title}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      )}
      {actor.Theatricals.length > 0 && (
        <div className="profile-reviews container">
          <div className="row">
            <section className="col-lg-9 order-lg-first">
              <h3 className="section-title lines">{labelData.theatricals}</h3>
              <div className="rewiew-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{labelData.year}</th>
                      <th scope="col">{labelData.theaterTitle}</th>
                      <th scope="col">{labelData.theaterRole}</th>
                      <th scope="col">{labelData.theaterLinks}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actor.Theatricals.sort((a, b) => a.year - b.year).map(theaterData => (
                      <tr key={theaterData.id}>
                        <td>{theaterData.year}</td>
                        <td>{theaterData.title}</td>
                        <td>{theaterData.role}</td>
                        <td>
                          <span className="soc-icons">
                            {theaterData.facebook && (
                              <a href={theaterData.facebook} target="_blank"><i className="fa fa-facebook-f"></i></a>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      )}

      {actor.Songs.length > 0 && (
        <div className="profile-reviews container">
          <div className="row">
            <section className="col-lg-9 order-lg-first">
              <h3 className="section-title lines">{labelData.songs}</h3>
              <div className="rewiew-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{labelData.year}</th>
                      <th scope="col">{labelData.songTitle}</th>
                      <th scope="col">{labelData.songBand}</th>
                      <th scope="col">{labelData.songLinks}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actor.Songs.sort((a, b) => a.year - b.year).map(songData => (
                      <tr key={songData.id}>
                        <td>{songData.year}</td>
                        <td>{songData.title}</td>
                        <td>{songData.band}</td>
                        <td>
                          <span className="soc-icons">
                            {songData.youtube && (
                              <a href={songData.youtube} target="_blank"><i className="fa fa-youtube"></i></a>
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      )}
      
      {actor.Trainings.length > 0 && (
        <div className="profile-reviews container">
          <div className="row">
            <section className="col-lg-9 order-lg-first">
              <h3 className="section-title lines">{labelData.trainings}</h3>
              <div className="rewiew-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{labelData.year}</th>
                      <th scope="col">{labelData.trainingSchool}</th>
                      <th scope="col">{labelData.trainingDegree}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actor.Trainings.sort((a, b) => a.year - b.year).map(trainingData => (
                      <tr key={trainingData.id}>
                        <td>{trainingData.year}</td>
                        <td>{trainingData.school}</td>
                        <td>{trainingData.degree}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      )}

      {actor.Credits.length > 0 && (
        <div className="profile-reviews container">
          <div className="row">
            <section className="col-lg-9 order-lg-first">
              <h3 className="section-title lines">{labelData.credits}</h3>
              <div className="rewiew-section">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{labelData.year}</th>
                      <th scope="col">{labelData.creditTitle}</th>
                      <th scope="col">{labelData.creditRole}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {actor.Credits.sort((a, b) => a.year - b.year).map(creditData => (
                      <tr key={creditData.id}>
                        <td>{creditData.year}</td>
                        <td>{creditData.title}</td>
                        <td>{creditData.role}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  )
}

export default Profile