import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/layout"
import SeoComp from "@/components/seo"
import Profile from "@/components/profile"

const ProfilePage = ({ data, pageContext }) => {
  const actor = data.strapiActor
  const { localizations } = data.strapiActor
  const global = data.strapiGlobal
  const seoData = {
    metaTitle: `${actor.name} ${actor.lastname} ${actor.stagename}`,
    metaDescription: actor.occupation ? actor.occupation.title : '',
    keywords: `${actor.name} ${actor.lastname} ${actor.stagename}`,
    mediaAlt: `${actor.name} ${actor.lastname}`,
    media: actor.headshot,
    preventIndex: false,
  }
  
  return (
    <>
      <SeoComp seo={seoData} global={global} />
      <Layout global={global} pageContext={{ ...pageContext, localizations }}>
        <Profile actor={actor} />
      </Layout>
    </>
  )
}

export default ProfilePage


export const query = graphql`
  query ProfilePageQuery($slug: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiActor(domain: { eq: $slug }, locale: { eq: $locale}) {
      id
      strapiId
      status
      name
      lastname
      stagename
      domain
      birth_at
      gender
      weight
      height
      locale
      headshot {
        alternativeText,
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 350
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      images {
        localFile {
          id
          publicURL
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              placeholder: BLURRED
            )
            full: gatsbyImageData(width: 1900)
          }
        }
      }
      eye {
        id
        name
      }
      hair {
        id
        name
      }
      body {
        name
        id
      }
      occupation {
        title
        id
      }
      Social {
        facebook
        instagram
        web
        id
      }
      Profile {
        imdb
        kinosan
        youtube
        id
      }
      Video {
        host
        code
      }
      bio
      languages {
        name
        id
      }
      skills {
        id
        title
        type
      }
      Trainings {
        degree
        id
        school
        year
      }
      Films {
        id
        type
        title
        role
        year
        movieLink {
          youtube
          kinosan
          imdb
        }
      }
      Awards {
        id
        title
        year
      }
      Theatricals {
        id
        title
        role
        year
        facebook
      }
      Songs {
        id
        title
        band
        year
        youtube
      }
      Credits {
        id
        title
        role
        year
      }
      localizations {
        locale
        id
      }
    }
  }
`